import React , { Component } from 'react'

import { t } from '../services/i18n'
import { locales } from '../config/i18n'
import LocalizedLink from '../containers/LocalizedLink'
import {AuthButtonModal} from './AuthButtonModal'
import { AuthButton } from './AuthButton'
import { Link } from 'react-router-dom'

import { history } from '../helpers'
import ITClogo from '../img/ITC_Logo.png'
import $ from "jquery"
import i18next from 'i18next';

class AppHeader extends Component {

    componentDidMount() {
        
        $( '.dropdown-menu a.dropdown-toggle' ).on( 'click', function ( e ) {
            var $el = $( this );
            var $parent = $( this ).offsetParent( ".dropdown-menu" );
            if ( !$( this ).next().hasClass( 'show' ) ) {
                $( this ).parents( '.dropdown-menu' ).first().find( '.show' ).removeClass( "show" );
            }
            var $subMenu = $( this ).next( ".dropdown-menu" );
            $subMenu.toggleClass( 'show' );
            
            $( this ).parent( "li" ).toggleClass( 'show' );
    
            $( this ).parents( 'li.nav-item.dropdown.show' ).on( 'hidden.bs.dropdown', function ( e ) {
                $( '.dropdown-menu .show' ).removeClass( "show" );
            } );
            
             if ( !$parent.parent().hasClass( 'navbar-nav' ) ) {
                $el.next().css( { "top": $el[0].offsetTop, "left": $parent.outerWidth() - 0 } );
            }
    
            return false;
        } ); 
        
    }
    render() {
        if (window.location.pathname.match("/embedded/")) {
            return null
        }
        else {
            return (

                <header>

                    <div className="container-full">
                        <div className="row">

                            <div id="top-header-cont" className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <nav id="top-header">
                                    <div className="top-nav float-right">
                                        <ul>
                                            {/* {console.log('history',history.location.pathname.substring(3), i18next.language)} */}
                                            {/* <AuthButtonModal></AuthButtonModal> */}
                                            <li><a className="register-link" href="https://mas-admintools.intracen.org/accounts/Registration.aspx" target="_blank">{t('signup')}</a></li>
                                            {locales.map(locale => (
                                                <li style={{ textDecoration: locale.code === i18next.language ? "underline" : "none" }}>
                                                    <Link
                                                        key={locale.code}
                                                        to={`/${locale.code}${history.location.pathname.substring(3)}`}
                                                        className="langBtn"
                                                    >
                                                        {locale.name}
                                                    </Link>
                                                </li>
                                            ))
                                            }

                                        </ul>
                                    </div>
                                    <div id="lang-select">

                                    </div>

                                </nav>
                            </div>

                            <div className="logo-cont col-6 col-sm-6 col-md-12 col-lg-12">

                                <LocalizedLink to="/home" className="navbar-brand float-left">
                                    <img
                                        src={ITClogo}
                                        width="90%"
                                        height=""
                                        className="logo"
                                        alt={t('app_name')}
                                    />

                                </LocalizedLink>

                                <h1 className="float-left noneMobile"><LocalizedLink to="/home" className="navbar-logo">MARKET ANALYSIS TOOLS PORTAL</LocalizedLink></h1>
                                <h2 className="float-left noneMobile"><LocalizedLink to="/home" className="navbar-logo">{t('app_sub_title')}</LocalizedLink></h2>
                            </div>

                            <div id="main-nav-cont" className=" col-4 col-sm-3 col-md-2 col-lg-12">
                                <nav className="navbar navbar-expand-lg navbar-light btco-hover-menu">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                        <ul data-toggle="collapse" data-target="#navbarNavDropdown" className="navbar-nav ml-auto pt-0">

                                            <li key="home" className="nav-item"><LocalizedLink to="/home" className="nav-link">{t('menu_lbl_home')}</LocalizedLink></li>
                                            <li key="nav-link-list" className="nav-item dropdown active">


                                                <a className="nav-link dropdown-toggle" href="https://www.macmap.org/matportal/index.html" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {t('menu_lbl_tools')}
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li><a className="dropdown-item" target="_blank" href={"https://www.trademap.org/" + i18next.language + "/Index.aspx"}>Trade Map</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href={"https://www.macmap.org/" + i18next.language + "/"}>Market Access Map</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href={"http://exportpotential.intracen.org/" + i18next.language + "/#/home"}>Export Potential Map</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href="https://procurementmap.intracen.org/">Procurement Map</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href="https://www.investmentmap.org/home">Investment Map</a> </li>
                                                    <li> <a className="dropdown-item" target="_blank" href="https://findrulesoforigin.org/">Rules of origin</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href="https://mpi.intracen.org/home">Market Price Information</a> </li>
                                                    <li><a className="dropdown-item" target="_blank" href="https://epingalert.org/en">E-ping</a> </li>

                                                </ul>
                                            </li>

                                            <li key="nav-link-Sublist" className="nav-item dropdown" >
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('menu_lbl_portals')}</a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink2">

                                                    <li key="nav-link-Sublist2" className="nav-item dropdown">
                                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('sub_menu_portals_global')}</a>
                                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink3">
                                                            <li><a className="dropdown-item" target="_blank" href="http://globaltradehelpdesk.org/en">Global Trade Helpdesk</a></li>
                                                        </ul>
                                                    </li>

                                                   
                                                    <li key="nav-link-Sublist5" className="nav-item dropdown">
                                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink5" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('sub_menu_portals_regional')}</a>
                                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink5">                                                            
                                                            <li> <a className="dropdown-item" target="_blank" href="https://euromed.tradehelpdesk.org/en/">EuroMed Trade Helpdesk</a></li>
                                                            <li> <a className="dropdown-item" target="_blank" href="https://catiportal.org/en/">Central Asia - CATI portal</a></li>
                                                            <li> <a className="dropdown-item" target="_blank" href="https://eap.tradehelpdesk.org/en"> Eastern Partnership Trade Helpdesk</a></li>

                                                            {/*<li><a class="dropdown-item dropdown-toggle" href="#">Subsubmenu</a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#">Subsubmenu action aa</a></li>
                                                <li><a class="dropdown-item" href="#">Another subsubmenu action</a></li>
                                            </ul>
                                        </li>
                                        <li><a class="dropdown-item dropdown-toggle" href="#">Second subsubmenu</a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#">Subsubmenu action bb</a></li>
                                                <li><a class="dropdown-item" href="#">Another subsubmenu action</a></li>
                                            </ul>
                        </li>*/}
                                                        </ul>
                                                    </li>
                                                    <li key="nav-link-Sublist6" className="nav-item dropdown">
                                                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink6" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{t('sub_menu_portals_sectoral')}</a>
                                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink6">
                                                            <li> <a className="dropdown-item" target="_blank" href="https://www.cottonportal.org/cotton">Cotton Portal</a></li>
                                                        </ul>
                                                    </li>

                                                </ul>

                                            </li>



                                            <li key="countryprofile" className="nav-item"><LocalizedLink to="/country-profile" className="nav-link">{t('countryprofile')}</LocalizedLink></li>



                                            <li key="learning" className="nav-item"><LocalizedLink to="/learning" className="nav-link">{t('learning')}</LocalizedLink></li>
                                            {/*} <li><LocalizedLink to="/merchants" className="nav-link">{t('APItest')}</LocalizedLink></li>*/}




                                            <li key="nav-link-list" className="nav-item dropdown active">


                                                <a className="nav-link dropdown-toggle" href="https://www.macmap.org/matportal/index.html" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    {t('menu_lbl_about')}
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                                    <li key="data_availability" className="nav-item"><LocalizedLink to="/data-availability" className="nav-link">{t('data_availability')}</LocalizedLink></li>

                                                    <li key="news" className="nav-item"><LocalizedLink to="/news" className="nav-link">{t('news')}</LocalizedLink></li>

                                                    <li key="news" className="nav-item"><LocalizedLink to="/joinus" className="nav-link">{t('sub_menu_about_join')}</LocalizedLink></li>

                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                </nav>




                            </div>

                        </div>
                    </div>
                </header>
            )
        }
        
        
    }
}
export default (AppHeader)